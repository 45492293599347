/*
 * 업무구분 : 청약 바이오인증
 * 화면 명 : MSPPS731D
 * 화면 설명: 지문 검증 화면
 */
 <template>
  <ur-page-container class="msp" title="피보험자 지문검증" :show-title="true" type="subpage" :topButton="true"
      @on-header-left-click="fn_HeaderBackBtnHandler">    

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-ps-wrap">
      <ur-box-container alignV="start" componentid="" direction="column" class="mt30 pb100">
        <div v-show="showResult">
        <span class="con-area dsD fwb fs22rem">검증결과</span>
</div>
        <div class="con-area mt20 mb30" v-show="showResult">
          
          <!-- status : 일치, 불일치, 재촬영 -->
          <div class="text-blue-box txt-center"  :class="{'' : verificationResult === 'match', 'bgcolor-5' : verificationResult === 'mismatch', 'bgcolor-1' : verificationResult === 'error' }">
            <div class="mt14 fs16rem">대상계약과의 지문검증 결과</div>
            <div class="mt20 mb14 fex-clumn">
              <mo-icon class="fs45rem" icon="msp-staus-check"   v-if="verificationResult === 'match'">msp-staus-check</mo-icon>
              <mo-icon class="fs45rem" icon="msp-staus-warning" v-if="verificationResult === 'mismatch'">msp-staus-warning</mo-icon>
              <mo-icon class="fs45rem" icon="msp-staus-refresh" v-if="verificationResult === 'error'">msp-staus-refresh</mo-icon>
              <b class="fwb mt10 fs22rem" :class="{'crTy-blue4' : verificationResult === 'match', 'crTy-orange2' : verificationResult === 'mismatch', 'crTy-bk1' : verificationResult === 'error' }">{{ status }}</b>
            </div>
          </div>

        </div>

        <msp-expand btn-area-first title-first :expanded="!showResult" ref="showContent" class="mo-list-expand agree-expand bd-T-Ty1" btn-area-class="fexTy3 pt5 pb5"  >
          <template #title>
            <div class="fexTy1">
              <span class="fwb fs22rem">고객확인사항</span>
            </div>
          </template>
          <template #btn>
              <mo-button class="link-arrow down"></mo-button>
          </template>
          <template #content>
            <div class="pal2430">
              <div class="w100 mt50" v-for="item in itemList" :key="item.index">
                <span class="dsD fwm fs18rem">{{item.title}}</span>
                <span class="mt10 w100 h40px fs17rem bd-b-Ty1 fexTy5-1">{{item.content}}</span>
              </div>

            </div>

          </template>
        </msp-expand>

      </ur-box-container>
      
    </ur-box-container>
    <!-- Content 영역 end -->
    <template>
        <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" v-if="!verificationResult" @click="fn_TabletCaptureButtonClicked">촬영</mo-button>
            <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" v-if="verificationResult === 'error'" @click="fn_TabletCaptureButtonClicked">재촬영</mo-button>
            <mo-button componentid="" color="normal" shape="border" size="medium" class="ns-btn-round blue" v-if="verificationResult === 'match'|| verificationResult === 'mismatch'" @click="fn_BackBtn">지문검증 완료</mo-button>
          </div>
        </ur-box-container>
      </template>
  </ur-page-container>
</template>

<script>
/***********************************************************************************
* INCLUDE 영역
***********************************************************************************/
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'


export default {

  /***********************************************************************************
  * Vue 파일 속성 정의 영역
  * - name : 화면ID동일 영문만 기입 특수문자 한글 기입시 에러남
  * - screenId : 화면ID
  * - components : UI TAG 컴포넌트 정의
  ***********************************************************************************/
  name: 'MSPPS731D', 
  screenId: 'MSPPS731D', 
  components: {
    
  },//componets,

  /***********************************************************************************
  * 화면 UI Property 정의
  * - 파리미터 받는 변수 props 정의
  ***********************************************************************************/
  props:{
     rowPopupData: Object,
     verifyTargetInfo: {
       type: Object,
       default: undefined
     },
     isFlashEnv: Boolean
  },//props

  /***********************************************************************************
  * 화면 전역 변수 선언 영역
  * - UI 바인딩 및 스크립트 화면 전역변수 정의
  ***********************************************************************************/
  data () {
    return {
      status : '',
      serverChoice: '', // 서버 상태(개발 development, 검증 stage, 운영 production)
      title: '지문 검증',
      itemList: [
        // {
        //   key: 'prodNm',
        //   title: '상품명',
        //   // content: '통합유니버설종신보험3.0(무배당,보증비용부과형)'
        //   content: ''
        // },
        {
          key: 'vuchId',
          title: '영수증번호',
          // content: '00000000000000000000',
          content: '',
          normalLetter: true
        },
        {
          key: 'custNm',
          title: '고객명',
          // content: '통합유니버설종신보험3.0(무배당,보증비용부과형)'
          content: '',
          normalLetter: true
        },
        {
          key: 'knb',
          title: '주민등록번호(앞자리)',
          // content: '8321224-1******',
          content: '',
          half: 'left',
          normalLetter: true
        },
        {
          key: 'fingerInfo',
          title: '등록 당시 지문정보',
          // content: '오른손 검지',
          content: '',
          half: 'right',
          noMargin: true
        },
        {
          key: 'agreeDtm',
          title: '피보험자 지문촬영 동의일자',
          // content: '2020년 5월 24일 15:00',
          content: '',
          half: 'left'
        },
        {
          key: 'elstDtm',
          title: '피보험자 지문 전자서명 일시',
          // content: '2020년 5월 24일 15:00',
          content: '',
          half: 'right'
        }
      ],
      insuDetails: {
        cnsltNo: undefined,
        mnPrdtNm: undefined,
        prcd: undefined,
        vuchId: undefined,
        agreeDtm: undefined,
        elstDtm: undefined,
        elstInsrdPartnNo: undefined,
        elstPartnNo: undefined,
        custNm: undefined,
        mnContrNm: undefined,
        knb: undefined,
        livePstnCd: undefined,
        livePstnNm: undefined,
        userType: undefined
      },
      bioPblcKeyVer: '', // 공개키 버전
      bioPblcKey: '', // 공개키
      bioLicenseKey: '', // 지문인증 라이센스
      bioSiteId: '', // 지문인증 참가기관 코드
      bioAuthCode: '', // 인증기술 코드
      confirmPushRequested: false,
      confirmFailedToPush: false,
      confirmTabletCapturing: false,
      confirmRepolling: false,
      confirmVerifyFailed: false,
      verificationResult: undefined, // undefined: 미수행, match: 일치, mismatch: 불일치, etc: 잠시후 다시 시도해 주세요.
      showResult: false,
      pollingState: 0, // 0: 폴링 중 아님, 1이상 : 폴링 중임
      lastClicked: 'none' // 마지막으로 눌린 버튼 종류 'mobile' : 모바일 촬영 요청 버튼, 'tablet' : 태블릿 촬영 버튼
    }
  },//data

 /***********************************************************************************
  * Life Cycle 함수 정의 영역
  * - 각단계별 예약 함수에 맞게 스크립트 작성       
  ***********************************************************************************/

  // Life Cycle Step-1 인스턴스 초기화 후 데이터 관찰/이벤트/감시자 설정 전 호출 - 호출 부모 우선
  beforeCreate () {},//beforeCreate

  // Life Cycle Step-2 인스턴스가 작성된 후 동기적 호출 - this 접근 가능, el 접근 불가 - 호출 부모 우선  
  created () {
    console.log('★★★★★★★★지문촬영 가능 환경 여부 fn_PocBioAuthEnv() = '+this.isFlashEnv)

    this.fn_init()
  },//created

  // Life Cycle Step-3 마운트되기 바로 직전 전 호출 - this 접근 가능, el 접근 불가 - 호출 자식 우선
  beforeMount () {},//beforeMount

  // Life Cycle Step-4 마운트 된 후 호출 - 여기서 부터 모든 자원 접근 가능 - 호출 자식 우선
  // 이곳에서 초기설정 스크립트 작성
  mounted () {
     // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  // Life Cycle Step-5 props 정의된 데이터가 변경되고 렌더링 되기 전 호출되는 수시호출함수
  beforeUpdate () {},//beforeUpdate

  // Life Cycle Step-6 props 데이터가 변경되고 렌더링 업데이트 후 호출되는 수시호출함수
  updated () {},//updated

  // Life Cycle Step-7 인스턴스가 Remove 되기 전 호출
  beforeDestroy () {},//beforeDestroy

  // Life Cycle Step-8 인스턴스가 Remove 된 후 호출
  destroyed () {},//destroyed

  
  /***********************************************************************************
   * watch 정의 영역
   * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 함수 정의 
   *   (변수에 대한 값 변경시 반응형 콜백 처리)
   *   ex) data 영역 정의한 익명변수 userNm 
   *       userNm : function() { this.message + ' 님 안녕하세요!'}
  ***********************************************************************************/
  watch: {
    'insuDetails.mnPrdtNm' (v) {
      this.itemList.filter(e => e.key === 'prodNm').forEach((e) => { e.content = v })
    },
    'insuDetails.vuchId' (v) {
      this.itemList.filter(e => e.key === 'vuchId').forEach((e) => { e.content = v })
    },
    'insuDetails.custNm' (v) {
      this.itemList.filter(e => e.key === 'custNm').forEach((e) => { e.content = v })
    },
    'insuDetails.knb' (v) {
      let knbRgx = /(.{6})(.{1})(.{6})/ // 예) 993131-1******
      let fmt = knbRgx.test(v) ? v.replace(knbRgx, '$1') : ''
      this.itemList.filter(e => e.key === 'knb').forEach((e) => { e.content = fmt })
    },
    'insuDetails.agreeDtm' (v) {
      // let dt = new Date(v)
      // let fmt = dt.getFullYear() + '년 ' + dt.getMonth() + '월 ' + dt.getDay() + '일 ' + dt.getHours() + ':' + dt.getMinutes()
      this.itemList.filter(e => e.key === 'agreeDtm').forEach((e) => { e.content = v })
    },
    'insuDetails.elstDtm' (v) {
      // let dt = new Date(v)
      // let fmt = dt.getFullYear() + '년 ' + dt.getMonth() + '월 ' + dt.getDay() + '일 ' + dt.getHours() + ':' + dt.getMinutes()
      this.itemList.filter(e => e.key === 'elstDtm').forEach((e) => { e.content = v })
    },
    'insuDetails.livePstnNm' (v) {
      this.itemList.filter(e => e.key === 'fingerInfo').forEach((e) => { e.content = v })
    },
    'verifyTargetInfo' (v) {
      if (!this.verifyTargetInfo) {
        return
      }
      this.fn_RefreshDetails()
    }
  },//watch

 /***********************************************************************************
  * Computed 함수 정의 영역
  * - data 영역에 바인딩 변수 정의한 값에 대한 값 변경시 지정 호출 getter 함수 정의
  *   지정 함수는 반드시 리턴 하는 함수로 작성
  *   (변수에 대한 값 변경시 반응형 getter 함수 지정 처리)
  *   ex) data 영역 정의한 익명변수 userNm 
  *   userNm : function() { return this.userNm = this.userNm? this.userNm.trim() : '' } 
  ***********************************************************************************/
  computed: {
    // 폴링중 여부
    isPolling () {
      return this.pollingState > 0
    },
    // 모바일 푸시 후 폴링 중 여부
    isMobilePolling () {
      return this.isPolling > 0 && this.lastClicked === 'mobile'
    }
  },//computed

 /***********************************************************************************
  * 사용자 함수 정의 영역
  ***********************************************************************************/

  methods: {
    /******************************************************************************
    * Function명  : fn_HeaderBackBtnHandler
    * 설명        : Header Back 타이틀 클릭 핸들러
    ******************************************************************************/
    fn_HeaderBackBtnHandler() {
      this.$emit('close')
    },// fn_HeaderBackBtnHandler
    fn_BackBtn() {
      // this.$router.go(-1)      
      this.$router.push({name: 'MSPBC002M'})
    },

    /******************************************************************************
    * Function명 : fn_init
    * 설명       : 초기화
    ******************************************************************************/
    fn_init: function () {
      this.serverChoice = process.env.NODE_ENV
      this.fn_RefreshDetails()
    },
    /******************************************************************************
    * Function명 : fn_RefreshDetails
    * 설명       : 지문검증 대상 상세 정보 얻기
    ******************************************************************************/
    fn_RefreshDetails () {
      if (!this.verifyTargetInfo) {
        return 
      }
      this.insuDetails.vuchId = this.verifyTargetInfo.vuchId
      this.insuDetails.elstPartnNo = this.verifyTargetInfo.elstPartnNo
      this.insuDetails.cnsltNo = this.verifyTargetInfo.clctCnsltNo
      this.insuDetails.agreeDtm = this.verifyTargetInfo.liveAthntCnsntYmd + ' ' + this.verifyTargetInfo.liveAthntCnsntDtm
      this.insuDetails.elstDtm = this.verifyTargetInfo.elstYmd + ' ' + this.verifyTargetInfo.elstDtm

      this.insuDetails.custNm = this.verifyTargetInfo.custNm
      this.insuDetails.knb = this.verifyTargetInfo.custRrnEncr
      this.insuDetails.userType = '피보험자'
      this.insuDetails.livePstnCd = this.verifyTargetInfo.livePstnCd
      this.insuDetails.livePstnNm = this.verifyTargetInfo.livePstnNm

      this.insuDetails.mnPrdtNm = this.verifyTargetInfo.mnPrdtNm

      this.bioPblcKeyVer = this.verifyTargetInfo.bioPublicKeyVersion
      this.bioPblcKey = this.verifyTargetInfo.bioPublicKey
      this.bioLicenseKey = this.verifyTargetInfo.bioLicenseKey
      this.bioSiteId = this.verifyTargetInfo.bioComyCd
      this.bioAuthCode = this.verifyTargetInfo.bioScCd
    },
    /******************************************************************************
    * Function명 : fn_MobileCaptureButtonClicked
    * 설명       : 모바일 촬영 버튼 눌림
    ******************************************************************************/
    fn_MobileCaptureButtonClicked () {
      this.lastClicked = 'mobile'
      this.fn_PushToMobile()
    },
    /******************************************************************************
    * Function명 : fn_TabletCaptureButtonClicked
    * 설명       : 태블릿 촬영 버튼 눌림
    ******************************************************************************/
    fn_TabletCaptureButtonClicked () {
      // this.lastClicked = 'tablet'
      // this.fn_ShowTabletCapturingAlert()
      // to-be 물어보지않고 바로 촬영
      if (this.verifyTargetInfo === undefined) {
        self.getStore('toast').dispatch('ADD', '지문촬영 정보 없음')
        return
      }

      if(!this.isFlashEnv){
        this.getStore('confirm').dispatch('ADD', '지문촬영은 플래시가 있는 기기에서 진행가능합니다. <br><br> 스마트폰에서 진행해주세요.')
      }else{
        this.fn_StartCapture()
      }

    },
    /******************************************************************************
    * Function명 : fn_PushToMobile
    * 설명       : 모바일 촬영 요청 푸시 전송
    ******************************************************************************/
    fn_PushToMobile () {
      let self = this
      let pParams = { bioSvcTp: '3', bioSvcId: self.insuDetails.vuchId }
      this.post(this, pParams, 'txTSSPS40U1', 'S', {}, false)
        .then(function (response) {
          if (response.body) {
            if ( response.body.isPushYN === 'Y' ) {
              // self.confirmPushRequested = true confirm->toast로변경
              self.getStore('toast').dispatch('ADD', '사랑On미니로 촬영요청 하였습니다.')
              self.fn_StartVerification(true)
            } else {
              // 푸시 실패 또는 연결된 휴대폰 없음
              //self.confirmFailedToPush = true
              self.fn_FailedToPushConfirm()
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_FailedToPushConfirm
    * 설명       : Confirm 팝업 호출
    ******************************************************************************/
    fn_FailedToPushConfirm () {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title: this.$t('ps')['EPSC050'],
          content: this.$t('ps')['EPSC043'],
          single: true
        },
        listeners: {
          onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
           
          }
        }
      })
    },
    /******************************************************************************
    * Function명 : fn_StartVerification
    * 설명       : 지문 검증 결과 얻기 (fn_GetVerificationResult 메소드를 시간차를 두고 호출하기 위해 사용)
    ******************************************************************************/
    fn_StartVerification (immediately = false) {
      let self = this
      window.vue.getStore('progress').dispatch('ADD')
      setTimeout(function () {
        self.fn_GetVerificationResult()
        window.vue.getStore('progress').dispatch('SUB')
      }, immediately ? 10 : 1000)
    },
    /******************************************************************************
    * Function명 : fn_GetVerificationResult
    * 설명       : 지문 검증 결과 얻기
    ******************************************************************************/
    fn_GetVerificationResult () {
      let self = this
      if (self.isPolling > 0) {
        // 아직 폴링 중인 경우 리턴
        return
      }
      self.pollingState++

      let pParams = {}
      pParams.vuchId = this.insuDetails.vuchId
      pParams.elstPartnNo = this.insuDetails.elstPartnNo
      pParams.liveAthntCustScCd = '02'
      this.post(this, pParams, 'txTSSPS42S2', 'S', {}, false)
        .then(function (response) {
          self.pollingState--
          if (response && response.body) {
            if (response.body.curStatusCd === '06') {
              if (response.body.elstVrfRsltYn === 'Y') {
                // 일치
                self.verificationResult = 'match'
                self.status = '일치'
                self.showResult = true
                self.$refs.showContent.isExpand = false
                return
              } else if (response.body.elstVrfRsltYn === 'N') {
                // 불일치
                self.verificationResult = 'mismatch'
                self.status = '불일치'
                self.showResult = true
                self.$refs.showContent.isExpand = false
                return
              }
            }

            // 다시시도
            //self.confirmRepolling = true
            self.fn_RepollingConfirm()
          }
        })
        .catch(function (error) {
          self.pollingState--
          window.vue.error(error)
        })
    },
  /******************************************************************************
   * Function명 : fn_RepollingConfirm
   * 설명       : Confirm 팝업 호출
   ******************************************************************************/
   fn_RepollingConfirm () {
     this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         title: this.$t('ps')['EPSC047'],
         content: this.$t('ps')['EPSC048'],
         title_pos_btn: "네",
         title_neg_btn: "아니오"
       },
       listeners: {
         onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
           this.fn_StartVerification(true)
         },
         onPopupClose: () => {
           this.$bottomModal.close(this.lv_AlertPop);
        }
       }
     })
   },
    /******************************************************************************
    * Function명 : fn_ShowTabletCapturingAlert
    * 설명       : 태블릿으로 지문을 촬영할 것인지 되묻는 창을 띄우고, 동의시 지문 촬영 함수 호출
    ******************************************************************************/
    fn_ShowTabletCapturingAlert: function () {
      if (this.verifyTargetInfo === undefined) {
        self.getStore('toast').dispatch('ADD', '지문촬영 정보 없음')
        return
      }

      //this.confirmTabletCapturing = true
      this.fn_TabletCapturingConfirm()
    },
    /******************************************************************************
   * Function명 : fn_TabletCapturingConfirm
   * 설명       : Confirm 팝업 호출
   ******************************************************************************/
   fn_TabletCapturingConfirm () {
     this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
       properties: {
         title: this.$t('ps')['EPSC038'],
         content: this.$t('ps')['EPSC039'],
         title_pos_btn: "네",
         title_neg_btn: "아니오"
       },
       listeners: {
         onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
           this.fn_StartCapture()
         },
         onPopupClose: () => {
           this.$bottomModal.close(this.lv_AlertPop);
        }
       }
     })
   },
    /******************************************************************************
    * Function명 : fn_StartCapture
    * 설명       : 태블릿 캡쳐 시작
    ******************************************************************************/
    fn_StartCapture: function () {
      console.log('촬영 플러그인 실행')
      
      let self = this
      let args = {}
      args.serialNo = this.bioLicenseKey
      args.siteId = this.bioSiteId
      args.authCode = this.bioAuthCode
      args.publicKeyVer = this.bioPblcKeyVer
      args.publicKey = this.bioPblcKey
      args.captureOptions = []

      let captureOptionVerify = {}
      captureOptionVerify.captureMode = 'verify'
      captureOptionVerify.repeatCnt = '1'
      captureOptionVerify.serviceCode = '검증'
      captureOptionVerify.serviceId = this.insuDetails.vuchId
      captureOptionVerify.userType = this.insuDetails.userType
      captureOptionVerify.systemMessage = '촬영 대상자 : ' + this.insuDetails.custNm + '<br>서명된 지문 : ' + this.insuDetails.livePstnNm
      // captureOptionVerify.systemTextColor = 'FFFFFFFF'
      // captureOptionVerify.systemButtonColor = 'FFFFFFFF'
      captureOptionVerify.gender = this.$bizUtil.getGenderFromKnb(this.insuDetails.knb)
      captureOptionVerify.age = this.$bizUtil.getAgeFromKnb(this.insuDetails.knb) + ''
      captureOptionVerify.fingerMode = this.insuDetails.livePstnCd
      captureOptionVerify.useEnrolledPrivateInfo = 'false'

      args.captureOptions.push(captureOptionVerify)

      window.fdpbridge.exec('startCaptureFingerPrintPlugin', args,
        (result) => {
          if (result && result.data && result.data.state) {
            if (result.data.state === 'success') {
              if (result.data.results.length >= 1) {
                self.getStore('toast').dispatch('ADD', '촬영성공 되었습니다. 검증요청 진행중입니다...')
                let curCaptured = result.data.results[0]
                self.fn_VerifyTemplate(
                  curCaptured.state,
                  curCaptured.template,
                  curCaptured.templateHash,
                  curCaptured.secretKey,
                  curCaptured.privateInfo.verify_index_finger
                )
              } else {
                self.getStore('toast').dispatch('ADD', '촬영실패 되었습니다. (지문데이타가 이상합니다.)')
                self.verificationResult = 'error'
                self.status = '잠시 후 다시 시도해 주세요.'
                self.showResult = true
                self.$refs.showContent.isExpand = false
              }
            } else {
              self.getStore('toast').dispatch('ADD', '촬영실패,취소 되었습니다.')
              self.verificationResult = 'error'
              self.status = '잠시 후 다시 시도해 주세요.'
              self.showResult = true
              self.$refs.showContent.isExpand = false
            }
          } else {
            self.getStore('toast').dispatch('ADD', '촬영실패 되었습니다.')
            self.verificationResult = 'error'
            self.status = '잠시 후 다시 시도해 주세요.'
            self.showResult = true
            self.$refs.showContent.isExpand = false
          }
        },
        (error) => {
          if (error && error.data && error.data === 'No Support') {
            self.getStore('toast').dispatch('ADD', '미지원기기입니다. 플래쉬가 있는 스마트폰에서 시도해주세요.')
            return
          }
          
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_VerifyTemplate
    * 설명       : 촬영된 탬플릿 AP에 전송해서 검증 요청
    ******************************************************************************/
    fn_VerifyTemplate (state, template, templateHash, secretKey, fingerIndex) {
      let self = this
      let pParams = {
        bioComyCd: this.bioComyCd,
        bioScCd: this.bioSccd,
        bioSvcTp: '3', // 검증
        bioSvcId: this.insuDetails.vuchId,
        vuchId: this.insuDetails.vuchId,
        // vuhdInfo: PS186DUtil.lv_BioData.lv_VuhdData,
        // custInfo: PS186DUtil.lv_BioData.lv_CustData,
        liveAthntCustScCd: '02', // 피보험자인 경우 02
        listTemplet: []
      }
      let tmpObj = {
        state: state,
        captureMode: '1', // 인증 (검증 = 인증)
        template: template,
        templateHash: templateHash,
        secretKey: secretKey,
        livePstnCd: fingerIndex,
        errorDetail: ''
      }
      pParams.listTemplet.push(tmpObj)

      this.post(this, pParams, 'txTSSPS41U2', 'S', {}, false)
        .then((response) => {
          if (response && response.body) {
            if ( response.body.isProcOk === 'Y' ) {
              self.fn_StartVerification(true)
            } else {
              // self.confirmVerifyFailed = true
              self.VerifyFailedConfirm()
            }
          }
        })
        .catch((error) => {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : VerifyFailedConfirm
    * 설명       : Confirm 팝업 호출
    ******************************************************************************/
    VerifyFailedConfirm () {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          title: this.$t('ps')['EPSC056'],
          content: this.$t('ps')['EPSC057'],
          single: true
        },
        listeners: {
          onPopupConfirm: () => {
           this.$bottomModal.close(this.lv_AlertPop);
           
          }
        }
      })
    },

   


    _fn_End(){return}//코딩 종료 함수 메서드 함수 , 없음 주의

 },// methods

}//export default


</script>